import { css, ThemeProvider } from "@emotion/react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Contact from "../components/layout/Contact"
import Footer from "../components/layout/Footer"
import GlobalStyles, { theme } from "../components/layout/GlobalStyles"
import Nav from "../components/layout/Nav"
import Office from "../components/layout/Office"
import { WhiteButton } from "../components/layout/ReusableComponents"
import Seo from "../components/layout/Seo"

const Section = styled.section`
  background: ${({ theme }) => theme.darkBlue};
  padding: 32px;
  max-width: 1200px;

  @media (min-width: 830px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
  }

  @media (min-width: 1150px) {
    padding: 96px;
    border-radius: 32px;
    margin: 128px auto 64px;
  }
`
const NotFoundPage = () => {
  const [mobileNav, setMobileNav] = React.useState(false)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles mobileNav={mobileNav} />
      <Nav mobileNav={mobileNav} setMobileNav={setMobileNav} />
      <Section>
        <Seo title="404: Not found" />

        <StaticImage
          src="../images/404.png"
          alt="404 not found"
          layout="constrained"
          width={510}
          height={451}
        />
        <div>
          <h3
            css={css`
              margin: 64px 0 0;
              color: ${theme.white};

              @media (min-width: 1024px) {
                margin: 0;
              }
            `}
          >
            404
          </h3>
          <h1
            css={css`
              margin: 0;
              color: ${theme.white};
            `}
          >
            Uh-oh!
          </h1>
          <h3
            css={css`
              margin: 0;
              color: ${theme.white};
            `}
          >
            We couldnt find that page
          </h3>
          <p
            css={css`
              margin: 32px 0 8px;
              color: ${theme.white};
            `}
          >
            But maybe we could help you...
          </p>
          <WhiteButton as={Link} to="/contact-us/">
            Contact Us
          </WhiteButton>
          <h3
            css={css`
              margin: 64px 0 16px;
              color: ${theme.white};

              @media (min-width: 1024px) {
                margin-top: 128px;
              }
            `}
          >
            Contact Info:
          </h3>

          <p
            css={css`
              margin: 0;
              display: flex;
              align-items: center;
              word-wrap: break-word;
              color: ${theme.white};
            `}
          >
            <StaticImage
              src="../images/pin2.png"
              alt="location"
              width={14}
              height={18}
              layout="fixed"
              css={css`
                margin-right: 16px;
                flex-shrink: 0;
              `}
            />
            555 S Hercules Avenue #403 Clearwater, FL 33764
          </p>
          <a
            href="tel:+17274416060"
            css={css`
              color: ${theme.white};
              display: flex;
              align-items: center;
              word-wrap: break-word;
              margin: 8px 0;
            `}
          >
            <StaticImage
              src="../images/phone.png"
              alt="phone number"
              layout="fixed"
              css={css`
                margin-right: 16px;
                flex-shrink: 0;
              `}
            />{" "}
            727-441-6060
          </a>
          <a
            href="mailto:office@chamberlaindental.com"
            css={css`
              color: ${theme.white};
              display: flex;
              align-items: center;
              word-wrap: break-word;
            `}
          >
            <StaticImage
              src="../images/email.png"
              alt="email address"
              layout="fixed"
              css={css`
                margin-right: 16px;
                flex-shrink: 0;
              `}
            />
            office@chamberlaindental.com
          </a>
        </div>
      </Section>
      <Office />
      <Contact />
      <Footer />
    </ThemeProvider>
  )
}

export default NotFoundPage
